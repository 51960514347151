import React, { Component } from "react";
import logo from "../../assets/imgs/01_logo/logo-heldenflix.svg";

class NiceTry extends Component {
  render() {
    return (
      <section className="slide solution nicetry">
        <div>
          <img
            className="logo logo-groot"
            alt="Logo van Heldenflix"
            src={logo}
          ></img>
          <h4>
            gaat niet in zee met{" "}
            <span className="text-emphasis">valsspelers</span>.
          </h4>
        </div>
        
      </section>
    );
  }
}

export default NiceTry;
