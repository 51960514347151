import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import logo from "../assets/imgs/01_logo/logo-heldenflix.svg";

class ToolsHeader extends Component {
  render() {
    return (
      <header className="tools-header">
        <HashLink to="/oproep">
          <img
            className="logo logo-klein"
            alt="Logo van Heldenflix"
            src={logo}
          ></img>
        </HashLink>
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-dark dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Verander van tool
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            {/*
          <HashLink
              className="dropdown-item"
              type="button"
              to="/call-to-action"
            >
              Call to action
            </HashLink>
 */}
            <HashLink
              className="dropdown-item"
              type="button"
              to="/tools/flipcards"
            >
              Inzichten
            </HashLink>
            <HashLink
              className="dropdown-item"
              type="button"
              to="/tools/awards"
            >
              Awards
            </HashLink>
            <HashLink
              className="dropdown-item"
              type="button"
              to="/tools/inspiratie"
            >
              Bouwstenen
            </HashLink>
          </div>
        </div>
      </header>
    );
  }
}

export default ToolsHeader;
