import React, { Component } from "react";
import heliasFilmpje1 from "../../assets/video/helias_jeugd-en-wraak_small.mp4";
import heliasFilmpje2 from "../../assets/video/helias_einde_small.mp4";
class Opdracht6 extends Component {
  render() {
    return (
      <div id="opdracht6" className="solution">
        <h1>De held en de les</h1>

        <div className="video-grid">
          <div>
            <h4>1. Helias' jeugd en missie</h4>
            <video
              className="nieuw"
              src={heliasFilmpje1}
              frameBorder="0"
              controls
              controlsList="nodownload"
              width="100%"
              allowFullScreen
            ></video>
          </div>
          <div>
            <h4>2. Helias' volwassen leven</h4>
            <video
              className="nieuw"
              src={heliasFilmpje2}
              frameBorder="0"
              controlsList="nodownload"
              controls="0"
              width="100%"
              allowFullScreen
            ></video>
          </div>
        </div>
      </div>
    );
  }
}

export default Opdracht6;
