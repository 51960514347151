import React, { Component } from "react";
import Masonry from "react-masonry-css";
import { inspirationImages } from "../../assets/imgs/inspirationImages.js";
import ToolsHeader from "../ToolsHeader";

let randomImages = inspirationImages
  .map((value) => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value);

const images = randomImages.map(function (image) {
  return (
    <div className={"type " + image.type}>
      <img
        className={image.type ? image.type + "-img" : ""}
        alt={"Picture " + image.id}
        src={image.file}
      ></img>
      <div>{image.type}</div>
    </div>
  );
});

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  600: 1,
};

class Inspiration extends Component {
  render() {
    return (
      <div className="slide" id="inspirationSlide">
        <header>
          <ToolsHeader />
        </header>
        <h3>
          <span className="text-emphasis-rood">30 bouwstenen</span> voor nieuwe
          heldenverhalen
        </h3>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="inspiration-masonry"
          columnClassName="inspiration-masonry_column"
        >
          {images}
        </Masonry>
      </div>
    );
  }
}

export default Inspiration;
