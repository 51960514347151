import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import arrowDown from "../../assets/imgs/03_buttons/pagearrow-down.svg";

class VerderButton extends Component {
  render() {
    return (
      <HashLink
        className="verderButton"
        to={"/handleiding" + this.props.nextSection}
        style={{
          visibility: this.props.nextSection ? "visible" : "hidden",
        }}
      >
        <div>verder</div>
        <img src={arrowDown} alt="Arrow down" />
      </HashLink>
    );
  }
}

export default VerderButton;
