import React, { Component } from "react";
import logoBib from "../assets/imgs/01_logo/logo_bib.jpg";
import logoSponsor from "../assets/imgs/01_logo/zegelMdNL.jpeg";
import logoOetang from "../assets/imgs/01_logo/naked--no-grunge.png";

class LogoFooter extends Component {
  render() {
    return (
      <footer>
        <div>
          <a
            href="https://www.consciencebibliotheek.be/nl"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="logoBib"
              src={logoBib}
              alt="Logo van de Erfgoedbibliotheek Hendrik Conscience"
            />
          </a>
          <a href="https://www.mdnl.nl/" target="_blank" rel="noreferrer">
            <img
              src={logoSponsor}
              alt="Logo van de Maatschappij der Nederlandse letterkunde"
              className="logoSponsor"
            />
          </a>
        </div>
        <div>
          <a href="https://oetang.be/" target="_blank" rel="noreferrer">
            <img
              src={logoOetang}
              alt="Logo van Oetang Learning Designers"
              className="logoOetang"
            />
          </a>
        </div>
      </footer>
    );
  }
}

export default LogoFooter;
