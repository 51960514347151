import { initialState } from "../reducers/reducer";

export const fetchAwards = () => {
  return function (dispatch) {
    dispatch({
      type: "FETCH_AWARDS",
      payload: initialState,
    });
  };
};

export const editAward = (award, newWinner) => {
  return function (dispatch) {
    dispatch({
      type: "EDIT_AWARD",
      payload: { award, newWinner },
    });
  };
};

export const fetchFlipcards = () => {
  return function (dispatch) {
    dispatch({
      type: "FETCH_FLIPCARDS",
      payload: initialState,
    });
  };
};

export const changeFlipstate = (flipcard, flipstate) => {
  return function (dispatch) {
    dispatch({
      type: "CHANGE_FLIPSTATE",
      payload: { flipcard, flipstate },
    });
  };
};
