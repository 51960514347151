import "./scss/index.scss";
import "normalize.css";
import { Switch, Route, HashRouter } from "react-router-dom";
import Manual from "./pages/Manual";
import LandingPage from "./pages/LandingPage";
import Oproeppagina from "./components/tools/Oproeppagina";
import Flipcards from "./components/tools/Flipcards";
import Awards from "./components/tools/Awards";
import Inspiration from "./components/tools/Inspiration";
import CallToAction from "./pages/CallToAction";
import Opdracht2 from "./pages/solutions/Opdracht2";
import Opdracht3 from "./pages/solutions/Opdracht3";
import Opdracht6 from "./pages/solutions/Opdracht6";
import Opdracht7 from "./pages/solutions/Opdracht7";
import Opdracht8 from "./pages/solutions/Opdracht8";
import Opdracht10 from "./pages/solutions/Opdracht10";
import NiceTry from "./pages/solutions/NiceTry";
import Opdracht1a from "./pages/solutions/Opdracht1a";
import Opdracht1b from "./pages/solutions/Opdracht1b";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          {/* Oplossingen */}
          <Route path="/opdracht1/bijlage1">
            <Opdracht1a />
          </Route>
          <Route path="/opdracht1/bijlage2">
            <Opdracht1b />
          </Route>
          <Route path="/opdracht2/oplossing-C8JEWKLV">
            <Opdracht2 />
          </Route>
          <Route path="/opdracht3/oplossing-XWGWFK64">
            <Opdracht3 />
          </Route>
          <Route path="/opdracht6/bijlage">
            <Opdracht6 />
          </Route>
          <Route path="/opdracht7/oplossing-MHZU2BTX">
            <Opdracht7 />
          </Route>
          <Route path="/opdracht8/oplossing-6R2C9WNN">
            <Opdracht8 />
          </Route>
          <Route path="/opdracht10/oplossing-U6LQ2G4E">
            <Opdracht10 />
          </Route>
          <Route
            path={[
              "/opdracht/oplossing",
              "/oplossing",
              "/opdracht1/oplossing",
              "/opdracht2/oplossing",
              "/opdracht3/oplossing",
              "/opdracht4/oplossing",
              "/opdracht5/oplossing",
              "/opdracht6/oplossing",
              "/opdracht7/oplossing",
              "/opdracht8/oplossing",
              "/opdracht9/oplossing",
              "/opdracht10/oplossing",
              "/opdracht11/oplossing",
              "/opdracht12/oplossing",
            ]}
          >
            <NiceTry />
          </Route>
          <Route path="/call-to-action">
            <CallToAction />
          </Route>
          <Route path="/handleiding">
            <Manual />
          </Route>
          <Route path="/oproep">
            <Oproeppagina />
          </Route>

          <Route path={["/awards", "/tools/awards"]}>
            <Awards />
          </Route>
          <Route path={["/inspiratie", "/tools/inspiratie"]}>
            <Inspiration />
          </Route>
          <Route path={["/tools", "/flipcards", "/tools/flipcards"]}>
            <Flipcards />
          </Route>
          <Route path={["/", "/home"]}>
            <LandingPage />
          </Route>
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
