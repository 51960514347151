import React, { Component } from "react";
import solution1 from "../../assets/imgs/05_solutions/oplossing_opdracht8-1@2x.png";
import solution2 from "../../assets/imgs/05_solutions/oplossing_opdracht8-2@2x.png";
import solution3 from "../../assets/imgs/05_solutions/oplossing_opdracht8-3@2x.png";
import solution4 from "../../assets/imgs/05_solutions/oplossing_opdracht8-4@2x.png";
import solution5 from "../../assets/imgs/05_solutions/oplossing_opdracht8-5@2x.png";
import solution6 from "../../assets/imgs/05_solutions/oplossing_opdracht8-6@2x.png";

class Opdracht8 extends Component {
  render() {
    return (
      <div id="opdracht8">
        <div className="opdracht8__desktop">
          <img src={solution1} alt="Oplossing van dooddoener 1"></img>
          <img src={solution2} alt="Oplossing van dooddoener 2"></img>
          <img src={solution3} alt="Oplossing van dooddoener 3"></img>
          <img src={solution4} alt="Oplossing van dooddoener 4"></img>
          <img src={solution5} alt="Oplossing van dooddoener 5"></img>
          <img src={solution6} alt="Oplossing van dooddoener 6"></img>
        </div>

        <div
          id="carouselExampleIndicators"
          data-interval="false"
          className="carousel slide opdracht8__mobile"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={solution1} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={solution2} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={solution3} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={solution4} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={solution5} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={solution6} className="d-block w-100" alt="..." />
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <svg
              className="carousel-control-prev-icon"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="#ff382c"
              class="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="#ff382c"
              class="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>

            {/*
              <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            */}
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    );
  }
}

export default Opdracht8;
