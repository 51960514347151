import React, { Component } from "react";

class StapButton extends Component {
  render() {
    // Gets step number from parent
    return <div className="stapButton">STAP {this.props.stap}</div>;
  }
}

export default StapButton;
