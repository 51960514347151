import React, { Component } from "react";
import logo from "../assets/imgs/01_logo/logo-heldenflix.svg";
import IconSprokkelboek from "../assets/icons/sprokkelboekje@2x.png";

class CallToAction extends Component {
  render() {
    return (
      <div id="call-to-action--mobile">
        <div>
          <img
            className="logo logo-groot"
            alt="Logo van Heldenflix"
            src={logo}
          ></img>
          <h3 id="slogan">Bootcamp</h3>
        </div>
        <div id="uitleg">
          <p>Beste Heldenflixer,</p>
          <p>
            Bedankt voor je inschrijving en welkom in de snelcursus{" "}
            <span className="text-emphasis-rood">verhalenschrijven</span> van
            Heldenflix, de streamingdienst van kleine en grote helden.
          </p>
          <p>
            We dompelen ons onder in{" "}
            <span className="text-emphasis-rood">
              verhalen van het verleden
            </span>
            , om van jou een{" "}
            <span className="text-emphasis-rood">
              verteller van de toekomst
            </span>{" "}
            te maken. Als je ons overtuigt prijkt jouw creatie binnenkort
            misschien wel op onze streamingdienst.
          </p>
          <p>De ontdekkingstocht begint zo:</p>
          <ul id="call-to-action__instructies">
            <li className="stapP">
              Je krijgt een persoonlijk{" "}
              <span className="text-emphasis-rood">sprokkelboekje</span>{" "}
              <img
                src={IconSprokkelboek}
                className="icon-sprokkelboek"
                alt="Icoontje van het sprokkelboekje"
              ></img>
              waarin je nieuwe inzichten verzamelt.
            </li>
            <li className="stapP">De begeleider verdeelt jullie in groepen.</li>
            <li className="stapP">
              Verspreid jullie in de ruimte en ga op ontdekking via opdrachten.
            </li>
          </ul>
          <p>
            Succes!
            <br />
            Team Heldenflix
          </p>
        </div>
      </div>
    );
  }
}

export default CallToAction;
