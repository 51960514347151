import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/imgs/01_logo/logo-heldenflix.svg";
import next from "../../assets/icons/130-next.svg";
// import qr from "../../assets/qr/qr_call-to-action.svg";
import qrWhite from "../../assets/qr/qr-code_call-to-action.png";
import LogoFooter from "../LogoFooter";

class Oproeppagina extends Component {
  render() {
    return (
      <section className="slide" id="oproeppagina">
        <div id="oproeppagina__wrapper">
          <div>
            <img
              className="logo logo-groot"
              alt="Logo van Heldenflix"
              src={logo}
            ></img>
            <h3>Bootcamp</h3>
          </div>
          <div id="call-to-action">
            <div>
              <h2>Schrijf je nu in!</h2>
              <p>
                Heldenflix zoekt nieuwe verhalenschrijvers voor de verhalen van
                de toekomst. Verdiep je daarvoor eerst in de verhalen van het
                verleden.
              </p>
              <p>
                Laat je inspireren <br />
                en vertel ons <i>jouw</i> verhaal.
              </p>
              {/*
            <ul>
              <li>Word de nieuwe scenarist van Heldenflix</li>
              <li>Laat je inspireren door oude verhalen</li>
              <li>
                Vertel ons <i>jouw</i> verhaal
              </li>
            </ul>
             */}
            </div>
            <div>
              <h5>
                <strong>Scan</strong> en <strong>neem deel</strong>
              </h5>
              <Link to="/call-to-action">
                <img src={qrWhite} alt="QR naar de call-to-actionpagina"></img>
              </Link>
            </div>
          </div>
          <Link className="button--large" to="/tools/flipcards">
            Lestools
            <button className="button-next">
              <img src={next} alt="Next icon"></img>
            </button>
          </Link>
          <div className="reviews reviews-left">
            <div className="review">
              <p className="review-comment">
                "De Heldenflix bootcamp bespaart je jaren aan oefening."
              </p>
              <p className="review-author">- Anonieme best-seller auteur</p>
              <p className="review-stars">
                <i className="icon-star"></i>
                <i className="icon-star"></i>
                <i className="icon-star"></i>
                <i className="icon-star"></i>
                <i className="icon-star-o"></i>
              </p>
            </div>
            <div className="review">
              <p className="review-comment">
                "Verfrissend - een streamingdienst met oog voor aanstormend
                talent."
              </p>
              <p className="review-author">- New York Times</p>
              <p className="review-stars">
                <i className="icon-star"></i>
                <i className="icon-star"></i>
                <i className="icon-star"></i>
                <i className="icon-star"></i>
                <i className="icon-star-half-o"></i>
              </p>
            </div>
            <div className="review">
              <p className="review-comment">
                "Verhelderend om te ontdekken hoe anderen omgaan met problemen."
              </p>
              <p className="review-author">- The Joker</p>
              <p className="review-stars">
                <i className="icon-star"></i>
                <i className="icon-star"></i>
                <i className="icon-star-half-o"></i>
                <i className="icon-star-o"></i>
                <i className="icon-star-o"></i>
              </p>
            </div>
          </div>
          <div className="reviews reviews-right">
            <div className="review">
              <p className="review-comment">
                "Heldenflix levert het bewijs: de kracht van verhalen is van
                alle tijden."
              </p>
              <p className="review-author">- Hollywoord screen writer</p>
              <p className="review-stars">
                <i className="icon-star"></i>
                <i className="icon-star"></i>
                <i className="icon-star"></i>
                <i className="icon-star-half-o"></i>
                <i className="icon-star-o"></i>
              </p>
            </div>
            <div className="review">
              <p className="review-comment">
                "Net als een bibliotheek brengt Heldenflix verhalen naar mensen.
                Op hun beurt veranderen zij de wereld."
              </p>
              <p className="review-author">- Expert heldenverhalen</p>
              <p className="review-stars">
                <i className="icon-star"></i>
                <i className="icon-star"></i>
                <i className="icon-star"></i>
                <i className="icon-star"></i>
                <i className="icon-star"></i>
              </p>
            </div>
          </div>
        </div>
        <LogoFooter />
      </section>
    );
  }
}

export default Oproeppagina;
