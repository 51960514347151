import React, { Component } from "react";
import solution from "../../assets/imgs/05_solutions/oplossing_opdracht3@2x.png";
class Opdracht3 extends Component {
  render() {
    return (
      <div id="opdracht3">
        <img src={solution} alt="Oplossing van opdracht 3" />
      </div>
    );
  }
}

export default Opdracht3;
