import React, { Component } from "react";
import { connect } from "react-redux";
import ToolsHeader from "../ToolsHeader";
import { changeFlipstate } from "../../redux/actions/actions.js";

class Flipcards extends Component {
  constructor() {
    super();
    this.state = {
      isFlipped: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(index) {
    let isFlipped = [...this.state.isFlipped];
    isFlipped[index] = !isFlipped[index];
    this.setState({ isFlipped });
  }

  render() {
    return (
      <div className="slide" id="flipcardSlide">
        <header>
          <ToolsHeader />
        </header>
        <h3>
          Heldenverhalen van vroeger en nu in{" "}
          <span className="text-emphasis-rood">10 inzichten</span>
        </h3>
        <div className="flipcards-grid">
          {this.props.flipcards.map((flipcard) => (
            <div className="flip-card" key={flipcard.id}>
              <div
                className={
                  flipcard.flipped === false
                    ? "flip-card-inner"
                    : "flip-card-inner flipped"
                }
              >
                <div
                  className="flip-card-front"
                  onClick={() => this.props.changeFlipstate(flipcard, true)}
                >
                  <h5>{flipcard.title}</h5>
                </div>
                <div
                  className="flip-card-back"
                  onClick={() => this.props.changeFlipstate(flipcard, false)}
                >
                  <p>
                    zowel{" "}
                    <span className="text-emphasis text-emphasis-rood">
                      vroeger
                    </span>{" "}
                    als{" "}
                    <span className="text-emphasis text-emphasis-rood">nu</span>
                  </p>
                  <h5>{flipcard.insight}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  flipcards: state.tools.flipcards,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeFlipstate: (flipcard, flipstate) =>
      dispatch(changeFlipstate(flipcard, flipstate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Flipcards);
