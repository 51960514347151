import React, { Component } from "react";
import solution from "../../assets/imgs/05_solutions/oplossing_opdracht10@2x.png";
class Opdracht10 extends Component {
  render() {
    return (
      <div id="opdracht10">
        <img src={solution} alt="Oplossing van opdracht 10." />
      </div>
    );
  }
}

export default Opdracht10;
