import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import arrowUp from "../../assets/imgs/03_buttons/pagearrow-up.svg";

class TerugButton extends Component {
  render() {
    return (
      <HashLink
        className="terugButton"
        to={"/handleiding" + this.props.previousSection}
        style={{
          visibility: this.props.previousSection ? "visible" : "hidden",
        }}
      >
        <img src={arrowUp} alt="Arrow up" />
        <div>terug</div>
      </HashLink>
    );
  }
}

export default TerugButton;
