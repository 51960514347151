import React, { Component } from "react";
import solution from "../../assets/imgs/05_solutions/oplossing_opdracht2@2x.png";

class Opdracht2 extends Component {
  render() {
    return (
      <div id="opdracht2">
        <img src={solution} alt="Oplossing van opdracht 2" />
      </div>
    );
  }
}

export default Opdracht2;
