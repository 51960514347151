import React, { Component } from "react";
import heldenprent from "../../assets/imgs/02_prenten/heldenprent.jpg";
import $ from "jquery";
import "bootstrap";

$(function () {
  $('[data-toggle="popover"]').popover();
});
$(".popover-dismiss").popover({
  trigger: "focus",
});

class Opdracht7 extends Component {
  render() {
    return (
      <div className="solution solution__opdracht7">
        <div className="solution__opdracht7-instruction">
          <p>Scroll en duid een held aan voor meer info</p>
        </div>
        <img
          src={heldenprent}
          alt="Een tekening van oude en recente helden."
        ></img>
        <a
          id="hercules"
          tabindex="0"
          className="btn-tooltip"
          role="button"
          data-toggle="popover"
          data-trigger="focus"
          data-html="true"
          data-placement="auto"
          data-animation="true"
          data-template={`<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`}
          data-title={""}
          data-content={`De naam ‘<b class="text-emphasis-rood">Hercules</b>’ betekent ‘beroemd door de godin Hera’, maar in onze taal staat het ondertussen gelijk aan ‘een sterke man’ of ‘krachtpatser’. Spierballen helpen in recente tijden ook om een superheld te zijn. Denk maar aan de Hulk, ook bekend om zijn groene kleurtje.`}
        ></a>
        <a
          id="blackPanther"
          tabindex="0"
          className="btn-tooltip"
          role="button"
          data-toggle="popover"
          data-trigger="focus"
          data-html="true"
          data-placement="auto"
          data-animation="true"
          data-template={`<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`}
          data-title={""}
          data-content={`<b class="text-emphasis-rood">Black Panther</b> ken je misschien uit de filmzalen, maar een held afbeelden met de uiterlijke kenmerken van een katachtige is niet eens zo origineel! In een Georgische ridderroman uit de middeleeuwen trad een gelijkaardige held naar voren: de ridder in het pantervel. En ook Hercules liep rond in het vel van een leeuw die hij zelf verslagen had.`}
        ></a>
        <a
          id="greenKnight"
          tabindex="0"
          className="btn-tooltip"
          role="button"
          data-toggle="popover"
          data-trigger="focus"
          data-html="true"
          data-placement="auto"
          data-animation="true"
          data-template={`<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`}
          data-title={""}
          data-content={`De <b class="text-emphasis-rood">Groene Ridder</b> werd door Sir Gawain (of Walewein) met de bijl onthoofd... en bleef leven! Ondanks deze opvallende eigenschap werd hij toch maar vernoemd naar wat hij ‘s ochtends uit de kleerkast nam. Het verhaal werd getrouw verfilmd in The Green Knight (2021)`}
        ></a>
        <a
          id="spiderman"
          tabindex="0"
          className="btn-tooltip"
          role="button"
          data-toggle="popover"
          data-trigger="focus"
          data-html="true"
          data-placement="auto"
          data-animation="true"
          data-template={`<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`}
          data-title={""}
          data-content={`Een spinnenbeet is soms genoeg om superkrachten te krijgen. Peter Benjamin Parker maakte het mee en ging voortaan door het leven als <b class="text-emphasis-rood">Spider-Man</b> . Ook vroeger koos niet elke held vrijwillig zijn lot. Vaak gaven verraad, moord of liefde aanleiding om zich als held te ontpoppen.`}
        ></a>
        <a
          id="galien"
          tabindex="0"
          className="btn-tooltip"
          role="button"
          data-toggle="popover"
          data-trigger="focus"
          data-html="true"
          data-placement="auto"
          data-animation="true"
          data-template={`<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`}
          data-title={""}
          data-content={`<b class="text-emphasis-rood">Galien Rethoré</b> was dapper als een leeuw, immuun voor vergif én zijn wonden heelden in drie dagen tijd. Niet voor niets kreeg hij de bijnaam Rethoré of re(s)toré, de hersteller. Zijn eigenschappen stelden hem in staat om de heerschappij van zijn meester Karel de Grote in ere te herstellen.`}
        ></a>
        <a
          id="blancefloerFloris"
          tabindex="0"
          className="btn-tooltip"
          role="button"
          data-toggle="popover"
          data-trigger="focus"
          data-html="true"
          data-placement="auto"
          data-animation="true"
          data-template={`<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`}
          data-title={""}
          data-content={`<b class="text-emphasis-rood">Floris en Blancefloer</b> werden op dezelfde dag geboren, Pasques flourie (Palmpasen), en werden allebei naar een bloem genoemd. Sindsdien blijft het heldenduo een team... of toch tot Blancefloer naar Babylon gestuurd wordt en het echte avontuur begint...`}
        ></a>
        <a
          id="invisibleWoman"
          tabindex="0"
          className="btn-tooltip"
          role="button"
          data-toggle="popover"
          data-trigger="focus"
          data-html="true"
          data-placement="auto"
          data-animation="true"
          data-template={`<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`}
          data-title={""}
          data-content={`Soms zie je niet wie de held écht is, en maakt dat hem of haar net bijzonder! Bij <b class="text-emphasis-rood">Invisible Woman</b> is dat zo, maar ook helden uit de ridderromans wisten zich te vermommen in nood. Melusine verhult haar ware aard (half mens, half zeewezen). Zowel Olivier van Castillien als Ponthus verblijven tijdelijk als mysterieuze ridders in Engeland.`}
        ></a>
        <a
          id="zwanenridder"
          tabindex="0"
          className="btn-tooltip"
          role="button"
          data-toggle="popover"
          data-trigger="focus"
          data-html="true"
          data-placement="auto"
          data-animation="true"
          data-template={`<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`}
          data-title={""}
          data-content={`Toegegeven, op een boot voortgetrokken worden door een zwaan is minder spetterend dan op een ijsbaan in de lucht arriveren, zoals Iceman, maar mysterieus is het wel! De <b class="text-emphasis-rood">Zwanenridder Helias</b> is helemaal in mysterie gehuld, want niemand mocht weten van waar hij afkomstig was...`}
        ></a>
        <a
          id="joker"
          tabindex="0"
          className="btn-tooltip"
          role="button"
          data-toggle="popover"
          data-trigger="focus"
          data-html="true"
          data-placement="auto"
          data-animation="true"
          data-template={`<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`}
          data-title={""}
          data-content={`Altijd lachend van buiten, maar ellendig van binnen. Bij de superschurk <b class="text-emphasis-rood">The Joker</b> is niets wat het lijkt. Middeleeuwse helden pinkten ook wel eens een traantje weg. En Don Quixote kreeg van zijn vriend de bijnaam “Ridder met het ellendige gelaat”. Ook antihelden, met eigenschappen die je niet meteen aan een held zou toeschrijven, zijn van alle tijden.`}
        ></a>
        <a
          id="storm"
          tabindex="0"
          className="btn-tooltip"
          role="button"
          data-toggle="popover"
          data-trigger="focus"
          data-html="true"
          data-placement="auto"
          data-animation="true"
          data-template={`<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`}
          data-title={""}
          data-content={`Het weer kunnen veranderen is weinigen gegeven. De Marvelheldin <b class="text-emphasis-rood">Storm</b> doet het wel. Maar ook van Karel de Grote wordt gezegd dat hij het heelal kon bespelen: hij verlengde de nacht!`}
        ></a>
      </div>
    );
  }
}

export default Opdracht7;
