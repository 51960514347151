import React, { Component } from "react";

class Opdracht1b extends Component {
  render() {
    return (
      <div className="solution" id="solution-opdracht1b">
        <h1>De held en de missie</h1>
        <div className="video-grid">
          <div>
            <iframe
              className="nieuw"
              src="https://www.youtube.com/embed/naQr0uTrH_s"
              title="YouTube video player"
              frameBorder="0"
              controls="0"
              allowFullScreen
            ></iframe>
          </div>
          <div>
            <iframe
              className="nieuw"
              src="https://www.youtube.com/embed/wb49-oV0F78"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div>
            <iframe
              className="nieuw"
              src="https://www.youtube.com/embed/ueCr7YHO6Rc"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div>
            <iframe
              className="nieuw"
              src="https://www.youtube.com/embed/oaP20t9Qb_g"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Opdracht1b;
