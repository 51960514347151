import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/imgs/01_logo/logo-heldenflix.svg";
import next from "../assets/icons/130-next.svg";
import LogoFooter from "../components/LogoFooter";

class landingPage extends Component {
  render() {
    return (
      <section className="slide" id="landingPage">
        <div id="landingPage__wrapper">
          <div>
            <h3 id="slogan">
              Een lespakket over ridderromans, <br></br>voor verhalenschrijvers
              van de toekomst
            </h3>
            <img
              className="logo logo-groot"
              alt="Logo van Heldenflix"
              src={logo}
            ></img>
          </div>
          <div id="landingPage__explanation">
            <p>
              Het <span className="text-emphasis">lespakket</span> Heldenflix
              laat leerlingen van de 3de graad heldenverhalen van{" "}
              <span className="text-emphasis-rood">vroeger</span> en
              <span className="text-emphasis-rood"> nu</span> verkennen. In 2
              uur tijd duiken ze onder in de wondere wereld van boeken,
              bibliotheken en bingewatching om te leren wat middeleeuwse helden
              en hun missies tijdloos maakt.
            </p>
          </div>
          <Link className="button--large" to="/handleiding#waarom">
            Ontdek het lespakket
            <button className="button-next">
              <img alt="Next icon" src={next}></img>
            </button>
          </Link>
        </div>
        <LogoFooter></LogoFooter>
      </section>
    );
  }
}

export default landingPage;
