import personage1 from "../../assets/imgs/04_inspiratie/02_personages/personage1_600px.jpg";
import personage2 from "./04_inspiratie/02_personages/personage2_600px.jpg";
import personage3 from "./04_inspiratie/02_personages/personage3_600px.jpg";
import personage4 from "./04_inspiratie/02_personages/personage4_600px.jpg";
import personage5 from "./04_inspiratie/02_personages/personage5_600px.jpg";
import personage6 from "./04_inspiratie/02_personages/personage6_600px.jpg";
import personage7 from "./04_inspiratie/02_personages/personage7_600px.jpg";
import personage8 from "./04_inspiratie/02_personages/personage8_600px.jpg";
import personage9 from "./04_inspiratie/02_personages/personage9_600px.jpg";
import personage10 from "./04_inspiratie/02_personages/personage10_600px.jpg";
import locatie1 from "../../assets/imgs/04_inspiratie/01_locaties/locatie1_600px.jpg";
import locatie2 from "../../assets/imgs/04_inspiratie/01_locaties/locatie2_600px.jpg";
import locatie3 from "../../assets/imgs/04_inspiratie/01_locaties/locatie3_600px.jpg";
import locatie4 from "../../assets/imgs/04_inspiratie/01_locaties/locatie4_600px.jpg";
import locatie5 from "../../assets/imgs/04_inspiratie/01_locaties/locatie5_600px.jpg";
import locatie6 from "../../assets/imgs/04_inspiratie/01_locaties/locatie6_600px.jpg";
import locatie7 from "../../assets/imgs/04_inspiratie/01_locaties/locatie7_600px.jpg";
import locatie8 from "../../assets/imgs/04_inspiratie/01_locaties/locatie8_600px.jpg";
import locatie9 from "../../assets/imgs/04_inspiratie/01_locaties/locatie9_600px.jpg";
import locatie10 from "../../assets/imgs/04_inspiratie/01_locaties/locatie10_600px.jpg";

import attribuut1 from "../../assets/imgs/04_inspiratie/03_attributen/attribuut1_600px.jpg";
import attribuut2 from "../../assets/imgs/04_inspiratie/03_attributen/attribuut2_600px.jpg";
import attribuut3 from "../../assets/imgs/04_inspiratie/03_attributen/attribuut3_600px.jpg";
import attribuut4 from "../../assets/imgs/04_inspiratie/03_attributen/attribuut4_600px.jpg";
import attribuut5 from "../../assets/imgs/04_inspiratie/03_attributen/attribuut5_600px.jpg";
import attribuut6 from "../../assets/imgs/04_inspiratie/03_attributen/attribuut6_600px.jpg";
import attribuut7 from "../../assets/imgs/04_inspiratie/03_attributen/attribuut7_600px.jpg";
import attribuut8 from "../../assets/imgs/04_inspiratie/03_attributen/attribuut8_600px.jpg";
import attribuut9 from "../../assets/imgs/04_inspiratie/03_attributen/attribuut9_600px.jpg";
import attribuut10 from "../../assets/imgs/04_inspiratie/03_attributen/attribuut10_600px.png";

export const inspirationImages = [
  {
    id: 0,
    type: "personage",
    file: personage1,
  },
  {
    id: 1,
    type: "personage",
    file: personage2,
  },
  {
    id: 2,
    type: "personage",
    file: personage3,
  },
  {
    id: 3,
    type: "personage",
    file: personage4,
  },
  {
    id: 4,
    type: "personage",
    file: personage5,
  },
  {
    id: 5,
    type: "personage",
    file: personage6,
  },
  {
    id: 6,
    type: "personage",
    file: personage7,
  },
  {
    id: 7,
    type: "personage",
    file: personage8,
  },
  {
    id: 8,
    type: "personage",
    file: personage9,
  },
  {
    id: 9,
    type: "personage",
    file: personage10,
  },

  {
    id: 10,
    type: "locatie",
    file: locatie1,
  },
  {
    id: 11,
    type: "locatie",
    file: locatie2,
  },
  {
    id: 12,
    type: "locatie",
    file: locatie3,
  },
  {
    id: 13,
    type: "locatie",
    file: locatie4,
  },
  {
    id: 14,
    type: "locatie",
    file: locatie5,
  },
  {
    id: 15,
    type: "locatie",
    file: locatie6,
  },
  {
    id: 16,
    type: "locatie",
    file: locatie7,
  },
  {
    id: 17,
    type: "locatie",
    file: locatie8,
  },
  {
    id: 18,
    type: "locatie",
    file: locatie9,
  },
  {
    id: 19,
    type: "locatie",
    file: locatie10,
  },
  {
    id: 20,
    type: "attribuut",
    file: attribuut1,
  },
  {
    id: 21,
    type: "attribuut",
    file: attribuut2,
  },
  {
    id: 22,
    type: "attribuut",
    file: attribuut3,
  },
  {
    id: 23,
    type: "attribuut",
    file: attribuut4,
  },
  {
    id: 24,
    type: "attribuut",
    file: attribuut5,
  },
  {
    id: 25,
    type: "attribuut",
    file: attribuut6,
  },
  {
    id: 26,
    type: "attribuut",
    file: attribuut7,
  },
  {
    id: 27,
    type: "attribuut",
    file: attribuut8,
  },
  {
    id: 28,
    type: "attribuut",
    file: attribuut9,
  },
  {
    id: 29,
    type: "attribuut",
    file: attribuut10,
  },
];
