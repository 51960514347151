import React, { Component } from "react";
import { connect } from "react-redux";
import ToolsHeader from "../ToolsHeader";
import { fetchAwards, editAward } from "../../redux/actions/actions.js";

class Awards extends Component {
  componentDidMount() {
    fetchAwards();
  }

  constructor(props) {
    super(props);

    this.changeWinner.bind(this);
  }

  changeWinner(award, index, e) {
    let awards = [...this.state.awards];
    awards[index] = { title: award.title, winner: e.target.value };
    this.setState({ awards });
  }

  render() {
    const { awards } = this.props;

    return (
      <div className="slide" id="awardSlide">
        <header>
          <ToolsHeader />
        </header>
        <h3>
          <span className="text-emphasis-rood">12 awards</span> voor ambitieuze
          verhalenschrijvers
        </h3>
        <div className="awards-grid">
          {awards.map((award) => (
            <div key={award.id} className="award">
              <div className="award__icon">
                <img src={award.icon} alt="Award icon"></img>
              </div>
              <div className="award__information">
                <h5>Beste </h5>
                <h6 dangerouslySetInnerHTML={{ __html: award.title }}></h6>
                <form>
                  <input
                    id="input-winner"
                    type="text"
                    // onChange={(e) => this.changeWinner(award, index, e)}
                    onBlur={(e) => this.props.editAward(award, e.target.value)}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    placeholder={award.winner}
                  />
                </form>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  awards: state.tools.awards,
});

const mapDispatchToProps = (dispatch) => {
  return {
    editAward: (award, newWinner) => dispatch(editAward(award, newWinner)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Awards);
