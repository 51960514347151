import React, { Component } from "react";
import galienFilmpje from "../../assets/video/Galien_zoektocht-naar-vader_small.mp4";
class Opdracht1a extends Component {
  render() {
    return (
      <div className="solution" id="solution-opdracht1a">
        <h1>De held en de missie</h1>
        <div className="video-grid">
          <div>
            <video
              className="oud"
              width="100%"
              controlsList="nodownload"
              src={galienFilmpje}
              frameBorder="0"
              controls="0"
              allowFullScreen
            ></video>
          </div>
        </div>
      </div>
    );
  }
}

export default Opdracht1a;
