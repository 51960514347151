import React, { Component } from "react";
import "bootstrap";
import $ from "jquery";
import TerugButton from "../components/buttons/TerugButton";
import VerderButton from "../components/buttons/VerderButton";
import logo from "../assets/imgs/01_logo/logo-heldenflix.svg";
import StapButton from "../components/buttons/StapButton";
import ExternalButton from "../components/buttons/ExternalButton";
import DownloadButton from "../components/buttons/DownloadButton";
import IconSprokkelboek from "../assets/icons/sprokkelboekje@2x.png";

import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

$(document).ready(function () {
  $(window).on("hashchange", function () {
    var hash = window.location.hash.substr(1);
    if (hash) {
      $('a[href*="#"]').removeClass("manual__navbar-active");
      $('a[href="#' + hash + '"]').addClass("manual__navbar-active");
    }
  });
});

class Manual extends Component {
  state = {
    isActive: [true, false, false, false, false, false, false, false],
  };

  checkStatus(index) {
    if (this.state.isActive[index] === true) {
      return "manual__navbar-active";
    } else {
      return "";
    }
  }

  changeStatus(index) {
    let isActive = [];
    isActive[index] = true;
    this.setState({ isActive });
    this.checkStatus(index);
  }

  render() {
    return (
      <div className="manual">
        <nav
          className="navbar sticky-top navbar-dark bg-black navbar-mobile"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
        >
          <HashLink to="/home">
            <img
              className="navbar-brand logo logo-klein"
              alt="logo van Heldenflix"
              src={logo}
              width="100px"
            ></img>
          </HashLink>
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-dark dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Inhoudstafel
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <HashLink
                onClick={() => this.changeStatus(0)}
                className={this.checkStatus(0) + " dropdown-item"}
                to="#waarom"
              >
                Het lespakket
              </HashLink>

              <HashLink
                onClick={() => this.changeStatus(1)}
                className={this.checkStatus(1) + " dropdown-item"}
                to="#materiaal"
              >
                Deze handleiding
              </HashLink>

              <HashLink
                onClick={() => this.changeStatus(2)}
                className={this.checkStatus(2) + " dropdown-item"}
                to="#voorbereiding"
              >
                De voorbereiding
              </HashLink>

              <div className="divider"></div>
              <HashLink className="dropdown-item disabled" to="#fase1">
                De 3 fases
              </HashLink>
              <HashLink
                onClick={() => this.changeStatus(3)}
                className={this.checkStatus(3) + " dropdown-item"}
                to="#fase1"
              >
                Op ontdekkingstocht
              </HashLink>

              <HashLink
                onClick={() => this.changeStatus(4)}
                className={this.checkStatus(4) + " dropdown-item"}
                to="#fase2"
              >
                Inzichten samenleggen
              </HashLink>

              <HashLink
                onClick={() => this.changeStatus(5)}
                className={this.checkStatus(5) + " dropdown-item"}
                to="#fase3"
              >
                Nieuwe heldenverhalen
              </HashLink>

              <div className="divider"></div>
              <HashLink
                onClick={() => this.changeStatus(6)}
                className={this.checkStatus(6) + " dropdown-item"}
                to="#tips"
              >
                Didactische tips & tricks
              </HashLink>
              <HashLink
                onClick={() => this.changeStatus(7)}
                className={this.checkStatus(7) + " dropdown-item"}
                to="#feedback"
              >
                Contact en feedback
              </HashLink>
            </div>
          </div>
        </nav>
        {/*
        <nav
          className="navbar sticky-top navbar-dark bg-black navbar-mobile"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
        >
          <HashLink to="/home">
            <img
              className="navbar-brand logo logo-klein"
              alt="logo van Heldenflix"
              src={logo}
              width="100px"
            ></img>
          </HashLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <HashLink
                onClick={() => this.changeStatus(0)}
                className={this.checkStatus(0) + " nav-item nav-link"}
                to="#waarom"
              >
                Het lespakket
              </HashLink>

              <HashLink
                onClick={() => this.changeStatus(1)}
                className={this.checkStatus(1) + " nav-item nav-link"}
                to="#materiaal"
              >
                De handleiding
              </HashLink>

              <HashLink
                onClick={() => this.changeStatus(2)}
                className={this.checkStatus(2) + " nav-item nav-link"}
                to="#voorbereiding"
              >
                De voorbereiding
              </HashLink>

              <div className="divider"></div>
              <HashLink className="nav-item nav-link disabled" to="#fase1">
                De 3 fases
              </HashLink>
              <HashLink
                onClick={() => this.changeStatus(3)}
                className={this.checkStatus(3) + " nav-item nav-link"}
                to="#fase1"
              >
                Op ontdekkingstocht
              </HashLink>

              <HashLink
                onClick={() => this.changeStatus(4)}
                className={this.checkStatus(4) + " nav-item nav-link"}
                to="#fase2"
              >
                Inzichten samenleggen
              </HashLink>

              <HashLink
                onClick={() => this.changeStatus(5)}
                className={this.checkStatus(5) + " nav-item nav-link"}
                to="#fase3"
              >
                Nieuwe heldenverhalen
              </HashLink>

              <div className="divider"></div>
              <HashLink
                onClick={() => this.changeStatus(6)}
                className={this.checkStatus(6) + " nav-item nav-link"}
                to="#feedback"
              >
                Contact en feedback
              </HashLink>
            </div>
          </div>
        </nav>
     */}

        <nav id="manual__navbar">
          <HashLink to="/home">
            <img
              className="logo logo-klein"
              alt="logo van Heldenflix"
              src={logo}
              width="100px"
            ></img>
          </HashLink>
          <ul>
            <li>
              <HashLink
                onClick={() => this.changeStatus(0)}
                className={this.checkStatus(0)}
                to="#waarom"
              >
                Het lespakket
              </HashLink>
            </li>
            <li>
              <HashLink
                onClick={() => this.changeStatus(1)}
                className={this.checkStatus(1)}
                to="#materiaal"
              >
                Deze handleiding
              </HashLink>
            </li>
            <li>
              <HashLink
                onClick={() => this.changeStatus(2)}
                className={this.checkStatus(2)}
                to="#voorbereiding"
              >
                De voorbereiding
              </HashLink>
            </li>
            <li className="divider"></li>
            <li className="tussenkop">De 3 fases</li>
            <li>
              <HashLink
                onClick={() => this.changeStatus(3)}
                className={this.checkStatus(3)}
                to="#fase1"
              >
                Op ontdekkingstocht
              </HashLink>
            </li>
            <li>
              <HashLink
                onClick={() => this.changeStatus(4)}
                className={this.checkStatus(4)}
                to="#fase2"
              >
                Inzichten samenleggen
              </HashLink>
            </li>
            <li>
              <HashLink
                onClick={() => this.changeStatus(5)}
                className={this.checkStatus(5)}
                to="#fase3"
              >
                Nieuwe heldenverhalen
              </HashLink>
            </li>
            <li className="divider"></li>
            <li>
              <HashLink
                onClick={() => this.changeStatus(6)}
                className={this.checkStatus(6)}
                to="#tips"
              >
                Didactische tips & tricks
              </HashLink>
            </li>
            <li>
              <HashLink
                onClick={() => this.changeStatus(7)}
                className={this.checkStatus(7)}
                to="#feedback"
              >
                Contact en feedback
              </HashLink>
            </li>
          </ul>
        </nav>

        <main className="manual__pages">
          <section onMouseEnter={() => this.changeStatus(0)} id="waarom">
            <div className="nav__room"></div>
            <div className="manualPage">
              <TerugButton />

              <div className="manualPage__information">
                <h2
                  id="waarom_title"
                  className="manualPage__title title-hidden"
                >
                  Het lespakket
                </h2>
                <div className="manualPage__steps">
                  <h4>Wat is Heldenflix?</h4>
                  <p>
                    Het lespakket Heldenflix laat leerlingen een{" "}
                    <span className="text-emphasis">
                      snelcursus verhaalschrijven
                    </span>{" "}
                    volgen. De fictieve streamingdienst Heldenflix roept hen op
                    om de kneepjes van het schrijven te leren via oude
                    heldenverhalen en zich te ontpoppen tot vertellers van de
                    toekomst. Met inzichten over ridderromans, de overlevering
                    van boeken, verteltechnieken en verhaalstructuren maken de
                    aanstormende talenten{" "}
                    <span className="text-emphasis">creatieve teasers</span>{" "}
                    waarin Heldenflix wel eens geld kan zien...
                  </p>
                </div>
                <div className="manualPage__steps">
                  <h4>Hoe verloopt de activiteit?</h4>
                  <p>
                    De activiteit (2 uur) gaat door op locatie in de{" "}
                    <a
                      className="text-link"
                      href="https://www.consciencebibliotheek.be/nl"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Erfgoedbibliotheek Hendrik Conscience
                      <ExternalButton />
                    </a>{" "}
                    of in de klas met online en printbaar materiaal. Ze verloopt
                    in 3 fases:
                  </p>
                  <ul>
                    <li>
                      <span className="text-emphasis-rood">
                        Op ontdekkingstocht (50 min.):
                      </span>{" "}
                      Leerlingen vlinderen naar 10 gevarieerde opdrachten. Op
                      een speelse manier verkennen ze oude en nieuwe
                      heldenverhalen.
                    </li>
                    <li>
                      <span className="text-emphasis-rood">
                        Inzichten samenleggen (10 min.):
                      </span>{" "}
                      Verzamelde inzichten, zoals terugkerende verhaaltechnieken
                      en -structuren, worden in groep besproken.
                    </li>
                    <li>
                      <span className="text-emphasis-rood">
                        Nieuwe heldenverhalen (60 min.):
                      </span>{" "}
                      Leerlingen gaan zelf creatief aan de slag als
                      verhalenschrijvers, bereiden een teaser van hun
                      heldenverhaal voor en vallen in de prijzen.
                    </li>
                  </ul>
                  <p>
                    Bekijk het verloop van de workshop in enkele{" "}
                    <a
                      className="text-link"
                      href="https://youtu.be/unF5kmHLRHc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      beelden
                      <ExternalButton />
                    </a>
                    .
                  </p>
                </div>
                <div className="manualPage__steps">
                  <h4>Voor wie?</h4>
                  <ul>
                    <li>Leerlingen van de 3de graad ASO, TSO, BSO en KSO. </li>
                    <li>
                      Een brede selectie van eindtermen zijn te vinden in deze
                      handige, filterbare{" "}
                      <a
                        className="text-link"
                        target="_blank"
                        rel="noreferrer"
                        href="https://docs.google.com/spreadsheets/d/1XN3bEWbf16nJ2ReKi34p1ZfpACK_ZDMjewseiiTc75g/edit?usp=sharing"
                      >
                        doelenlijst
                        <ExternalButton />
                      </a>
                      .
                    </li>
                  </ul>
                </div>
              </div>

              <VerderButton nextSection={"#materiaal"} />
            </div>
          </section>
          <section onMouseEnter={() => this.changeStatus(1)} id="materiaal">
            <div className="nav__room"></div>
            <div className="manualPage">
              <TerugButton previousSection={"#waarom"} />

              <div className="manualPage__information">
                <h2
                  id="materiaal_title"
                  className="manualPage__title title-hidden"
                >
                  Deze handleiding
                </h2>
                <div className="manualPage__steps">
                  <h4>Hoe te gebruiken?</h4>
                  <p>
                    Deze online handleiding bevat alles wat je nodig hebt. Het
                    helpt je om de activiteit tot in de puntjes voor te
                    bereiden, maar je kan er ook op terugvallen tijdens de
                    workshop.
                  </p>
                  <p>
                    Wil je het verloop op papier? Download dan hier de{" "}
                    <a
                      className="text-link"
                      href="./downloads/Heldenflix_handleiding.pdf"
                      download
                    >
                      handleiding
                      <DownloadButton />
                    </a>
                    .
                  </p>
                </div>
                <div className="manualPage__steps">
                  <h4>Online tools</h4>
                  <p>
                    Enkele online tools bieden houvast tijdens de workshop,
                    zowel voor de leerlingen als voor de begeleider.
                  </p>
                  <ul>
                    <li>
                      Een{" "}
                      <Link
                        className="text-link"
                        to="/oproep"
                        target="_blank"
                        rel="noreferrer"
                      >
                        call to action
                        <ExternalButton />
                      </Link>{" "}
                      aan het begin van de les
                    </li>
                    <li>
                      Flipcards met 10{" "}
                      <HashLink
                        className="text-link"
                        to="/flipcards"
                        target="_blank"
                        rel="noreferrer"
                      >
                        inzichten
                        <ExternalButton />
                      </HashLink>{" "}
                      over heldenverhalen
                    </li>
                    <li>
                      Een overzicht met 12{" "}
                      <HashLink
                        className="text-link"
                        to="/awards"
                        target="_blank"
                        rel="noreferrer"
                      >
                        awards
                        <ExternalButton />
                      </HashLink>
                    </li>
                    <li>
                      Een pagina met{" "}
                      <HashLink
                        className="text-link"
                        to="/inspiratie"
                        target="_blank"
                        rel="noreferrer"
                      >
                        bouwstenen
                        <ExternalButton />
                      </HashLink>{" "}
                      als inspiratie voor nieuwe heldenverhalen{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <VerderButton nextSection={"#voorbereiding"} />
            </div>
          </section>
          <section onMouseEnter={() => this.changeStatus(2)} id="voorbereiding">
            <div className="nav__room"></div>
            <div className="manualPage">
              <TerugButton previousSection={"#materiaal"} />
              <div className="manualPage__information">
                <h2
                  id="voorbereiding_title"
                  className="manualPage__title title-hidden"
                >
                  De voorbereiding
                </h2>

                <div className="manualPage__steps">
                  <ul>
                    <li>
                      <a
                        className="text-link"
                        href="./downloads/Heldenflix_printmateriaal.zip"
                        download
                      >
                        Download
                        <DownloadButton />
                      </a>
                      de opdrachtfiches en het andere materiaal om te printen.
                      <ul>
                        <li>
                          10 opdrachtfiches met bijlagen (A4, enkelzijdig, x1)
                        </li>
                        <li>
                          12 steekkaarten met presentatievormen (A4,
                          enkelzijdig, x1)
                        </li>
                        <li>
                          Bijlage bij opdracht 'De held en de draden' (A3,
                          enkelzijdig, 1 per groep)
                        </li>
                        <li>
                          Sprokkelboekjes{" "}
                          <img
                            src={IconSprokkelboek}
                            alt="Icoontje van het sprokkelboekje"
                            className="icon-sprokkelboek"
                          ></img>{" "}
                          (A3, dubbelzijdig, 1 per leerling)
                        </li>
                      </ul>
                    </li>
                    <li>
                      Verspreid de opdrachtfiches (met bijlagen) over 10 hoeken.
                    </li>
                    <li>
                      Vorm duo's en trio's en geef elke leerling een persoonlijk
                      sprokkelboekje
                      <img
                        src={IconSprokkelboek}
                        alt="Icoontje van het sprokkelboekje"
                        className="icon-sprokkelboek"
                      ></img>
                      .
                    </li>
                    <li>
                      De leerlingen hebben een smartphone of tablet nodig voor
                      het scannen van QR-codes.
                    </li>
                    <li>
                      Projecteer de{" "}
                      <HashLink
                        className="text-link"
                        to="oproep"
                        target="_blank"
                        rel="noreferrer"
                      >
                        call to action
                        <ExternalButton />
                      </HashLink>
                      .
                    </li>
                  </ul>
                </div>
              </div>
              <VerderButton nextSection={"#fase1"} />
            </div>
          </section>
          <section onMouseEnter={() => this.changeStatus(3)} id="fase1">
            <div className="nav__room"></div>
            <div className="manualPage">
              <TerugButton previousSection={"#voorbereiding"} />

              <div className="manualPage__information">
                <h2 className="tussenkop tussenkop-groot title-hidden">
                  Fase 1
                </h2>
                <h2 id="fase1_title" className="manualPage__title title-hidden">
                  Op ontdekkingstocht
                </h2>
                <div className="manualPage__steps">
                  <h4>
                    <StapButton stap={1.1} />
                    De grote <i>open call</i> !
                  </h4>
                  <p>
                    Toon de{" "}
                    <HashLink
                      className="text-link"
                      to="/oproep"
                      target="_blank"
                      rel="noreferrer"
                    >
                      call to action
                      <ExternalButton />
                    </HashLink>
                    om de activiteit te starten. De leerlingen schrijven zich in
                    door de QR-code te volgen en krijgen op hun smartphone de
                    eerste instructies.
                  </p>
                </div>{" "}
                <div className="manualPage__steps">
                  <h4>
                    <StapButton stap={1.2} />
                    Opdrachten uitvoeren tussen vroeger en nu
                  </h4>
                  <p>
                    De duo's en trio's <strong>vlinderen</strong> van fiche naar
                    fiche en voeren de opdrachten grondig uit. Alles wat de
                    leerlingen nodig hebben vinden ze op de fiches.
                  </p>
                  <p>
                    Niet elke opdracht hoeft door elk groepje uitgevoerd te
                    worden.
                  </p>
                  <p>
                    Motiveer de leerlingen om antwoorden, inzichten en
                    ervaringen te verzamelen in hun individuele{" "}
                    <span className="text-emphasis">sprokkelboekjes</span>
                    <img
                      src={IconSprokkelboek}
                      alt="Icoontje van het sprokkelboekje"
                      className="icon-sprokkelboek"
                    ></img>
                    . De info zal van pas komen in de volgende fases van de
                    workshop.
                  </p>
                </div>{" "}
                <div className="manualPage__steps">
                  <h4>
                    <StapButton stap={1.3} />
                    Groeperen geblazen
                  </h4>
                  <p>
                    <strong>Rond af</strong>: roep iedereen samen en verzamel
                    het materiaal. Geef de leerlingen nog even om het
                    sprokkelboekje{" "}
                    <img
                      src={IconSprokkelboek}
                      alt="Icoontje van het sprokkelboekje"
                      className="icon-sprokkelboek"
                    ></img>{" "}
                    aan te vullen en vrij met elkaar te praten over hun
                    beleving.
                  </p>
                </div>
              </div>
              <VerderButton nextSection={"#fase2"} />
            </div>
          </section>
          <section onMouseEnter={() => this.changeStatus(4)} id="fase2">
            <div className="nav__room"></div>
            <div className="manualPage">
              <TerugButton previousSection={"#fase1"} />

              <div className="manualPage__information">
                <h2 className="tussenkop tussenkop-groot title-hidden">
                  Fase 2
                </h2>
                <h2 id="fase2_title" className="manualPage__title title-hidden">
                  Inzichten samenleggen
                </h2>
                <div className="manualPage__steps">
                  <h4>
                    <StapButton stap={2.1}></StapButton>
                    Projecteer de opdrachtflipcards
                  </h4>
                  <p>
                    De{" "}
                    <HashLink
                      className="text-link"
                      to="/flipcards"
                      target="_blank"
                      rel="noreferrer"
                    >
                      10 flipcards
                      <ExternalButton />
                    </HashLink>
                    leggen, per opdracht, een aangebracht inzicht bloot,
                    gebaseerd op gemeenschappelijke kenmerken tussen
                    heldenverhalen van vroeger en nu. Check bij de leerlingen of
                    ze de titels van de opdrachten kunnen linken aan wat ze
                    deden.
                  </p>
                </div>
                <div className="manualPage__steps">
                  <h4>
                    <StapButton stap={2.2}></StapButton>
                    Bespreek ervaringen en zet inzichten vast
                  </h4>
                  <p>
                    De leerlingen delen per opdracht hun inzichten en
                    ervaringen, met het sprokkelboekje{" "}
                    <img
                      src={IconSprokkelboek}
                      alt="Icoontje van het sprokkelboekje"
                      className="icon-sprokkelboek"
                    ></img>{" "}
                    als geheugensteun. Bespreek na en lok discussie uit.
                  </p>
                  <p>
                    Klik op de flipcards om een voor een de verworven inzichten
                    te tonen. Zo bespreek je ze, tot ze allemaal geflipt zijn.
                    Deze 10 vroeger-en-nu-spiegelingen zijn belangrijk voor het
                    vervolg van de activiteit!
                  </p>
                </div>
              </div>
              <VerderButton nextSection={"#fase3"} />
            </div>
          </section>
          <section onMouseEnter={() => this.changeStatus(5)} id="fase3">
            <div className="nav__room"></div>
            <div className="manualPage">
              <TerugButton previousSection={"#fase2"} />

              <div className="manualPage__information">
                <h2 className="tussenkop tussenkop-groot title-hidden">
                  Fase 3
                </h2>
                <h2 id="fase3_title" className="manualPage__title title-hidden">
                  Nieuwe heldenverhalen
                </h2>
                <div className="manualPage__steps">
                  <h4>
                    <StapButton stap={3.1} />
                    Verhaal verzinnen
                  </h4>
                  <p>
                    De leerlingen brainstormen over een eigen heldenverhaal door
                    de{" "}
                    <span className="text-emphasis">
                      stappen op de achterzijde van hun sprokkelboekje
                    </span>{" "}
                    <img
                      src={IconSprokkelboek}
                      alt="Icoontje van het sprokkelboekje"
                      className="icon-sprokkelboek"
                    ></img>{" "}
                    te volgen.
                  </p>
                  <p>
                    Naast de flipcards met inzichten krijgen de leerlingen nog
                    <span className="text-emphasis">
                      {" "}
                      extra hulpmiddelen
                    </span>{" "}
                    om de basiselementen van hun verhaal te bedenken.
                  </p>
                  <ul>
                    <li>
                      De groepjes dingen mee voor een of meerdere awards, om
                      richting te geven aan hun project. Met het{" "}
                      <HashLink
                        className="text-link"
                        to="/awards"
                        target="_blank"
                        rel="noreferrer"
                      >
                        awardoverzicht
                        <ExternalButton link={"/awards"} />
                      </HashLink>
                      kunnen ze hun awardambities bepalen.{" "}
                    </li>
                    <li>
                      De leerlingen kunnen ook snuisteren door de
                      inspiratiepagina, naar personages, attributen en locaties.
                      De QR-code voor deze tool is te vinden in hun{" "}
                      sprokkelboekje
                      <img
                        src={IconSprokkelboek}
                        alt="Icoontje van het sprokkelboekje"
                        className="icon-sprokkelboek"
                      ></img>
                      .{" "}
                    </li>
                  </ul>
                </div>{" "}
                <div className="manualPage__steps">
                  <h4>
                    <StapButton stap={3.2} />
                    Teasers maken
                  </h4>

                  <p>
                    Elk groepje kiest een steekkaart met een{" "}
                    <span className="text-emphasis">presentatievorm</span> om
                    zijn teaser aan Heldenflix voor te stellen. Alle instructies
                    en benodigdheden om de teaser te ontwikkelen staan op de
                    steekkaart vermeld.
                  </p>
                </div>
                <div className="manualPage__steps">
                  <h4>
                    <StapButton stap={3.3} />
                    Prijzen uitreiken!
                  </h4>
                  <p>
                    De groepjes stellen hun teaser voor. De klas bepaalt na
                    afloop samen wie welke award in ontvangst mag nemen. Vul de
                    titels van de winnende teaser in op het{" "}
                    <HashLink
                      className="text-link"
                      to="/awards"
                      target="_blank"
                      rel="noreferrer"
                    >
                      awardoverzicht
                      <ExternalButton />
                    </HashLink>{" "}
                    en vereeuwig dit feestelijk moment met een screenshot.
                  </p>
                </div>
              </div>
              <VerderButton nextSection={"#tips"} />
            </div>
          </section>
          <section onMouseEnter={() => this.changeStatus(6)} id="tips">
            <div className="nav__room"></div>
            <div className="manualPage">
              <TerugButton previousSection={"#fase3"} />

              <div className="manualPage__information">
                <h2
                  id="materiaal_title"
                  className="manualPage__title title-hidden"
                >
                  Didactische tips & tricks
                </h2>

                <div className="manualPage__steps">
                  <h2
                    className="tussenkop tussenkop-groot title-hidden"
                    style={{
                      display: "inline",
                      marginRight: "15px",
                      fontSize: "24px",
                    }}
                  >
                    Fase 1
                  </h2>
                  <h4 style={{ display: "inline" }}>Op ontdekkingstocht</h4>
                  <ul>
                    <li>
                      Benadruk vanaf het begin het{" "}
                      <span className="text-emphasis">
                        overkoepelende inzicht
                      </span>{" "}
                      van de workshop: heldenverhalen van vroeger en van nu
                      hebben veel gemeen!
                    </li>
                    <li>
                      Maak{" "}
                      <span className="text-emphasis">
                        {" "}
                        minder dan 10 groepen
                      </span>
                      , zo zijn er altijd meerdere hoeken vrij.
                    </li>
                    <li>
                      Rond indien nodig vroeger af. Vijf afgewerkte opdrachten
                      per groep is ruim voldoende.
                    </li>
                  </ul>
                </div>
                <div className="manualPage__steps">
                  <h2
                    className="tussenkop tussenkop-groot title-hidden"
                    style={{
                      display: "inline",
                      marginRight: "15px",
                      fontSize: "24px",
                    }}
                  >
                    Fase 2
                  </h2>
                  <h4 style={{ display: "inline" }}>Inzichten samenleggen</h4>

                  <ul>
                    <li>
                      Draai eerst een flipcard om en geef het inzicht mee.{" "}
                      <span className="text-emphasis">
                        Laat 1 of 2 groepen vertellen
                      </span>{" "}
                      wat ze bij de opdracht deden of noteerden.
                    </li>
                    <li>
                      Peil nadien kort naar begrip van het inzicht: herkennen ze
                      het inzicht in de opdracht?
                    </li>

                    <li>
                      Herhaal het{" "}
                      <span className="text-emphasis">
                        overkoepelende inzicht
                      </span>{" "}
                      van de workshop: dit alles maakt heldenverhalen tijdloos!
                    </li>
                  </ul>
                </div>
                <div className="manualPage__steps">
                  <h2
                    className="tussenkop tussenkop-groot title-hidden"
                    style={{
                      display: "inline",
                      marginRight: "15px",
                      fontSize: "24px",
                    }}
                  >
                    Fase 3
                  </h2>
                  <h4 style={{ display: "inline" }}>Nieuwe heldenverhalen</h4>

                  <ul>
                    <li>
                      Baken de brainstormfase goed af:{" "}
                      <span className="text-emphasis">help de groepen</span> om
                      de verhaalelementen af te vinken en geef zelf aan wanneer
                      ze klaar zijn om een presentatievorm te kiezen.
                    </li>
                    <li>
                      Leg de presentatiefiches op een centrale plek en laat de
                      groepen hun buikgevoel volgen bij hun keuze.
                    </li>
                    <li>
                      Benadruk dat een teaser altijd{" "}
                      <span className="text-emphasis">work in progress</span>{" "}
                      voorstelt. Perfectie is niet het doel!
                    </li>
                    <li>
                      Echt in tijdsnood? Verplaats de presentaties en
                      awarduitreiking naar een lesuur op een andere dag.
                    </li>
                  </ul>
                </div>
              </div>
              <VerderButton nextSection={"#feedback"} />
            </div>
          </section>
          <section onMouseEnter={() => this.changeStatus(7)} id="feedback">
            <div className="nav__room"></div>
            <div className="manualPage">
              <TerugButton previousSection={"#fase3"} />

              <div className="manualPage__information">
                <h2 id="fase3_title" className="manualPage__title title-hidden">
                  Contact en feedback
                </h2>
                <div className="manualPage__steps">
                  <h4>En wat nu?</h4>
                  <p>
                    Heb je nog vragen over de workshop, maak je graag een
                    afspraak om het materiaal{" "}
                    <span className="text-emphasis">op locatie</span> te
                    ontdekken of wil je meteen een workshop boeken in de
                    Erfgoedbibliotheek? Bezoek dan onze
                    <a
                      className="text-link"
                      href="https://consciencebibliotheek.be/nl/workshop-heldenflix"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      website
                      <ExternalButton />
                    </a>
                    of mail naar{" "}
                    <a
                      className="text-link"
                      href="mailto:educatie_ehc@antwerpen.be?SUBJECT=Heldenflix"
                    >
                      educatie_ehc@antwerpen.be
                    </a>
                  </p>
                  <p>
                    Toch liever{" "}
                    <span className="text-emphasis">in de klas</span>? Download
                    dan al het{" "}
                    <a
                      className="text-link"
                      href="./downloads/Heldenflix_printmateriaal.zip"
                      download
                    >
                      workshopmateriaal
                      <DownloadButton />
                    </a>{" "}
                    en de printbare{" "}
                    <a
                      className="text-link"
                      href="./downloads/Heldenflix_handleiding.pdf"
                      download
                    >
                      handleiding
                      <DownloadButton />
                    </a>{" "}
                    als spiekblad. Print en ga meteen aan de slag!
                  </p>
                </div>{" "}
                <div className="manualPage__steps">
                  <h4>Deel je ervaring!</h4>
                  <p>
                    Deel je bevindingen van de workshop met ons: foto's van je
                    klas in actie of een screenshot van het ingevulde
                    awardoverzicht. Stuur daarvoor naar{" "}
                    <a
                      className="text-link"
                      href="mailto:educatie_ehc@antwerpen.be?SUBJECT=Heldenflix"
                    >
                      educatie_ehc@antwerpen.be
                    </a>{" "}
                    of post het op sociale media met
                    #heldeninharnas@consciencebibliotheek
                  </p>
                </div>
              </div>
              <VerderButton />
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Manual;
