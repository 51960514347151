import attribuut from "../../assets/imgs/06_awardicons/attribuut.svg";
import bijrol from "../../assets/imgs/06_awardicons/bijrol.svg";
import held from "../../assets/imgs/06_awardicons/held.svg";
import hindernis from "../../assets/imgs/06_awardicons/hindernis.svg";
import locationhunter from "../../assets/imgs/06_awardicons/locationhunter.svg";
import moraalridder from "../../assets/imgs/06_awardicons/morraalridder.svg";
import ontknoping from "../../assets/imgs/06_awardicons/ontknoping.svg";
import plotwending from "../../assets/imgs/06_awardicons/plotwending.svg";
import pratenddier from "../../assets/imgs/06_awardicons/pratenddier.svg";
import slechterik from "../../assets/imgs/06_awardicons/slechterik.svg";
import spelmettijd from "../../assets/imgs/06_awardicons/spelmettijd.svg";
import tovertruc from "../../assets/imgs/06_awardicons/tovertruc.svg";

export const initialState = {
  flipcards: [
    {
      id: "1",
      title: "De held en de missie",
      insight:
        "Helden nemen hindernissen en lossen problemen op (tijdens heldentochten).",
      flipped: false,
    },
    {
      id: "2",
      title: "De held en de anderen",
      insight: "Heldenverhalen hebben een complex netwerk van personages.",
      flipped: false,
    },
    {
      id: "3",
      title: "De held en het onverwachtse",
      insight:
        "Onverwachtse wendingen als gevolg van magie of bovennatuurlijke krachten.",
      flipped: false,
    },
    {
      id: "4",
      title: "De held en de draden",
      insight:
        "Verhaaldraden kunnen eindeloos opgepikt en vervlochten worden (entrelacement, of vlechtwerk).",
      flipped: false,
    },

    {
      id: "6",
      title: "De held en de les",
      insight: "Elk verhaal heeft een moraal, of toch zeker betekenis(sen).",
      flipped: false,
    },
    {
      id: "5",
      title: "De held en de fans",
      insight:
        "Heldenverhalen kunnen verslavend zijn (en daar worden trucjes voor gebruikt).",
      flipped: false,
    },
    {
      id: "7",
      title: "De held en de naam",
      insight:
        "Heldennamen zijn goed gekozen: ze zeggen wat de held goed kan, graag doet of...",
      flipped: false,
    },
    {
      id: "8",
      title: "De held en de dooddoener",
      insight:
        "Stereotypes zijn handig in verhalen maar stroken meestal niet met de werkelijkheid...",
      flipped: false,
    },
    {
      id: "9",
      title: "Het verhaal en de bewaring",
      insight:
        "Verhalen bewaren is een heldendaad: het kost tijd, geld, goede wil en een beetje geluk.",
      flipped: false,
    },
    {
      id: "10",
      title: "Het verhaal en onze wereld",
      insight:
        "De boekdrukkunst en de digitale revolutie kunnen mensen bij elkaar brengen en de wereld veranderen.",
      flipped: false,
    },
  ],
  awards: [
    { id: "0", title: "plotwending", winner: "", icon: plotwending },
    { id: "1", title: "held", winner: "", icon: held },
    {
      id: "2",
      title: "slechterik (of Sleggeric)",
      winner: "",
      icon: slechterik,
    },
    {
      id: "3",
      title: "tovertruc (special effects)",
      winner: "",
      icon: tovertruc,
    },
    { id: "4", title: "probleem of hindernis", winner: "", icon: hindernis },
    { id: "5", title: "ontknoping", winner: "", icon: ontknoping },
    { id: "6", title: "bijrol (m/v/x)", winner: "", icon: bijrol },
    { id: "7", title: "location hunter", winner: "", icon: locationhunter },
    { id: "8", title: "attribuut", winner: "", icon: attribuut },
    { id: "9", title: "pratend dier", winner: "", icon: pratenddier },
    { id: "10", title: "moraalridder", winner: "", icon: moraalridder },
    { id: "11", title: "tijdsprong", winner: "", icon: spelmettijd },
  ],
};

function reducer(store = initialState, action) {
  switch (action.type) {
    case "FETCH_AWARDS":
      return {
        ...store,
      };
    case "EDIT_AWARD":
      return {
        ...store,
        awards: store.awards.map((award) =>
          award.id === action.payload.award.id
            ? { ...award, winner: action.payload.newWinner }
            : award
        ),
      };
    case "FETCH_FLIPCARDS":
      return {
        ...store,
      };
    case "CHANGE_FLIPSTATE":
      return {
        ...store,
        flipcards: store.flipcards.map((flipcard) =>
          flipcard.id === action.payload.flipcard.id
            ? { ...flipcard, flipped: action.payload.flipstate }
            : flipcard
        ),
      };
    default:
      return store;
  }
}

export default reducer;
